import HomePic from "./images/HomPicMedium.png"

const logotext = "";
const meta = {
    title: "Hamed Heravi",
    description: "A Software/Hardware Solutions Architect and Full stack developer, Based in London",
};

const introdata = {
    title: "I’m Hamed Heravi",
    animated: {
        first: "Welcome to my home page",
        second: "I am crazy about creating, developing and implementing Machine Learning solutions.",
        third: "Cloud platform Architect and Full-stack developer",
    },
    description: "In a nutshell I am a Full stack developer, clould platform Architect and Embedded system engineer.",
    your_img_url: HomePic,
};

const dataabout = {
    title: "About my self",
    aboutme: "Years of experience as an entrepreneur and co-founder due to establishing two pioneering startups namely “Tosee Tadbir Tavan Teb Co” and “KIGON GmbH” in Iran and Germany respectively. Intensive experience in developing innovative AI-based products such as BodyVision (patients' foot pressure and posture analysis device) and CogSim360 (mixed reality cognitive simulator).",
};
const worktimeline = [
    {
        jobtitle: "Research Assistant",
        where: "Brunel University, UK",
        date: "2021-now",
    },
    {
        jobtitle: "AIML - Tech Lead/Senior Software Engineer",
        where: "Z Prime Ltd (part-time)",
        date: "2022-now",
    },
    {
        jobtitle: "Co-Founder/ CTO | Product Manager",
        where: (
            <a href="https://kigon.eu">KIGON GmbH, Germany</a>
        ),
        date: "2020-now",
    },
    {
        jobtitle: "Senior AI developer | Machine Vision Scientist",
        where: "ROGON GmbH & Co. KG GmbH, Germany",
        date: "2019-2021",
    },
    {
        jobtitle: "Co-Founder & Managing director/CTO | AI/ML Tech Lead",
        where: "REHABSOON startup, Iran",
        date: "2015-2020",
    },
    {
        jobtitle: "Research assistant | Industrial-academic Projects",
        where: "Sahand University of Technology",
        date: "2014-2016",
    },
    {
        jobtitle: "Embedded System Engineer",
        where: "Tarrahan Control CO. (TCCO)",
        date: "2007-2013",
    },
];

const skills = [{
        name: "Python",
        value: 90,
    },
    {
        name: "C++",
        value: 95,
    },
    {
        name: "C#",
        value: 90,
    },
    {
        name: "JavaScript",
        value: 90,
    },
    {
        name: "React JS",
        value: 85,
    },
    {
        name: "CSS",
        value: 80,
    },
    {
        name: "Assembly Embedded",
        value: 90,
    },
    {
        name: "MATLAB",
        value: 90,
    },
];

const services = [
    {
        title: "Full stack developer",
        description: "No matter, the actuall web Front-End for the app or Front-End Electronics(Embedded) with connected Back-end",
    },
    {
        title: "Machine Learning and Vision research scientist",
        description: "Developing Machine Vision approches, includes both Deep learning based and traditional methods",
    },
    {
        title: "Cloud architect",
        description: "I design and architect cloud-based platform",
    }
];

const dataportfolio = [{
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/800/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },

    {
        img: "https://picsum.photos/400/600/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/300/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/550/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
    {
        img: "https://picsum.photos/400/700/?grayscale",
        desctiption: "The wisdom of life consists in the elimination of non-essentials.",
        link: "#",
    },
];

const contactConfig = {
    YOUR_EMAIL: "hamedheravi@icloud.com",
    //YOUR_FONE: "(555)123-4567",
    description: "Please feel free to contact me in any cases. I would be happy if I could help you. ",
    YOUR_SERVICE_ID: "service_id",
    YOUR_TEMPLATE_ID: "template_id",
    YOUR_USER_ID: "user_id",
};

const socialprofils = {
    //github: "https://github.com",
    facebook: "https://facebook.com",
    linkedin: "https://www.linkedin.com/in/hamed-heravi-b553725a/",
    //twitter: "https://twitter.com",
    //youtube: "https://youtube.com",
    //twitch: "https://twitch.com",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};